<template>
    <div class="index">
        <!-- 内容 -->
        <div class="index-news">
            <div class="news-content">
                <!-- 头部 -->
                <div class="news-content-title">新闻中心</div>
                <!-- 内容列表 -->
                <div class="new-list">
                    <div class="new-item" v-for="(item, index) in list" :key="index" @click="navto(item)">
                        <img :src="item.image"/>
                        <div class="new-item-info">
                            <div class="new-item-info-title">{{ item.title }}</div>
                            <p>{{ item.describe}}</p>
                        </div>
                        <div class="new-item-time">
                            <span>{{ item.releasetime_text2[1] }}</span>
                            <div class="new-tim-line"></div>
                            <div class="new-time-year">{{ item.releasetime_text2[0] }}</div>
                        </div>
                    </div>
                </div>
                
                
                
                
                <!-- <div class="news-list">
                    <div class="news-item" v-for="(item, index) in list" :key="index" @click="navto(item)">
                        <div class="news-item-left">
                            <span class="news-item-left-title">{{ item.releasetime_text2[1] }}</span>
                            虚线
                            <div class="news-item-left-line"></div>
                            <div class="news-item-left-time">{{ item.releasetime_text2[0] }}</div>
                        </div>

                        右侧内容
                        <div class="news-item-right">
                            <span>{{ item.title }}</span>
                            <p class="news-item-right-p">{{ item.describe }}</p>
                        </div>
                    </div>
                </div> -->
                <!-- 底部分页 -->
                <paging @changeIndex="changeIndex" :allNum="total"></paging>
            </div>
        </div>
    </div>
</template>
<script>
import paging from '@/components/paging'
export default {
    name: 'newsCenter',
    components:{
        paging
    },
    data(){
        return{
            list:[],
            page:1,//页
            limit:6,//条
            total:0,//总页数
            count:0,//总条数
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList(){
            this.$request({
                url:'/api/article/news',
                method:'POST',
                data:{
                    page:this.page,
                    limit:this.limit
                }
            }).then(res=>{
                if(res.code==1){
                    console.log(res,'新闻中心')
                    this.list=res.data.list
                    this.count=res.data.count
                    this.total=res.data.total
                }
            })
        },
        navto(item) {
            this.$router.push({
                path:"/news/newDetail",
                query:{
                    id:item.id
                }
            })
        },
        changeIndex(e){
            console.log(e) 
            this.page=e
            this.getList()
        },
    }
}
</script>
<style lang="scss" scoped>
    .index{
        width: 100vw;
    }
    @media screen and (max-width: 1200px){
        .news-content{
            width: 100%;
        }
    }
    .index-news{
        width: 100%;
        // height: 1145px;
        display: flex;
        justify-content: center;
        background: #F6F6EA;
    }
    .news-content{
        width: 1200px;
        background: #ffffff;
        padding: 60px;
    }
    // 头部
    .news-content-title{
        width: 100%;
        text-align: center;
        color: #333333;
        font-size: 36px;
        font-weight: 500;
        margin-bottom:50px;
    }
    // 内容列表
    
    .new-item{
        display: flex;
        // align-items: center;
        margin-bottom: 30px;
        cursor: pointer;
        padding-bottom: 30px;
        border-bottom: 1px solid #ccc;
    }
    .new-item-info{
        flex: 1;
        padding: 0 44px;
        display: flex;
        flex-direction: column;
    }
    .new-item-info-title{
        color: #313131;
        font-size: 20px;
        padding-bottom: 30px;
    }
    .new-item-info p{
        color: #7D7D7D;
        height: 42px;
        text-overflow:ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-indent: 2em;
    }
    .new-item-info:hover p {
        color: #26716C;
    }
    .new-item img{
        width: 160px;
        height: 160px;
    }
    .new-item-time{
        flex-shrink: 0;
        padding: 24px 0 24px 35px;
        border-left: 1px dashed #707070;
        color: #313131;
        font-size: 26px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .new-tim-line{
        margin: 13px 0 6px;
        width: 49px;
        height: 0;
        border: 1px solid #C1C1C1;
    }
    .new-time-year{
        color: #707070;
        font-size: 16px;
        font-weight: 400;
    }
    // .news-list{
    //     padding: 50px 0;
    //     display: flex;
    //     flex-direction: column;
    // }
    // .news-item{
    //     width: 100%;
    //     display: flex;
    //     align-items: center;
    //     margin-bottom: 20px;
    //     cursor: pointer;
    // }
    // .news-item-left{
    //     padding: 30px 35px;
    //     border: 1px solid #707070;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    // }
    // .news-item-left-title{
    //     color: #292828;
    //     font-size: 40px;
    //     font-weight: bold;
    // }
    // .news-item-left-line{
    //     width: 121px;
    //     margin: 20px 0;
    //     height: 1px;
    //     border-bottom: 1px dashed #BABABA;
    // }
    // .news-item-left-time{
    //     color: #929292;
    //     font-size: 16px;
    // }
    // // 右侧内容
    // .news-item-right{
    //     flex: 1;
    //     padding-left: 28px;
    //     display: flex;
    //     flex-direction: column;
    //     color: #333333;
    //     font-size: 20px;
    // }
    // .news-item-right-p{
    //     padding-top: 18px;
    //     color: #666666;
    //     font-size: 14px;
    //     text-overflow: -o-ellipsis-lastline;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     display: -webkit-box;
    //     -webkit-line-clamp: 4;
    //     line-clamp: 4;
    //     -webkit-box-orient: vertical;
    // }
</style>