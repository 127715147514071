<template>
    <div class="paging">
        <!-- <span class="paging-style" @click="backOne">返回首页</span> -->
        <span class="paging-style" :class="nowNum == 1 ? 'paging-style-none' : ''" @click="prev">上一页</span>
        <div class="paging-number">
            <div class="paging-number-list">
                <div class="paging-number-scroll" :style="{'transform': 'translateX(-'+left+'px)'}">
                    <div class="page-number" v-for="item in allNum" :class="item == nowNum ? 'page-number-bg' :''"
                     @click="changPage(item)">{{item}}</div>
                </div>
            </div>
            <div style="padding-left:13px">{{nowNum}}/{{allNum}}</div>
        </div>
        <span class="paging-style" :class="nowNum == allNum ? 'paging-style-none' : ''" @click="next">下一页</span>
        <!-- <span class="paging-style" @click="backFoot">最后一页</span> -->
    </div>
</template>
<script>
export default {
    created() {
        
    },
    data() {
        return {
            // allNum: 10,//总数
            nowNum: 1,//第几页
            left: 0
        }
    },
    props:{
        allNum:{
            type:Number,
            default:0,
        },
        // nowNum:{
        //     type:Number,
        //     default:0,
        // },
    },
    methods: {
        changPage(index) {
            this.nowNum = index
            this.$emit('changeIndex', index)
            console.log(index)
            if(this.nowNum === 1) {
                this.left = 0
                return 
            }
            if(this.allNum - index > 3) {
                this.left = (index-4)*36
            } else {
                this.left = (index-7)*36
            }
        },
        // 上一页
        prev() {
            if(this.nowNum > 1){
                this.changPage(this.nowNum - 1)
            }
        },
        // 下一页
        next() {
            if(this.nowNum < this.allNum){
                this.changPage(this.nowNum + 1)
            }
        },
        // 返回首页
        backOne() {
            this.nowNum = 1
            // this.left = 0
            this.changPage(this.nowNum)
        },
        // 最后一页
        backFoot() {
            this.changPage(this.allNum)
        }
    }
}
</script>
<style lang="scss" scoped>
    .paging{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
    }
    .paging-style{
        padding: 3px 12px;
        color: #ffffff;
        background: #9E9E9E;
        margin-right: 12px;
        cursor: pointer;
    }
    .paging-style-none{
        background: #DBDAD4;
    }
    .paging-number{
        padding: 3px 30px;
        display: flex;
        align-items: center;
        margin-right: 12px;
    }
    .paging-number-list{
        width: 100px;
        overflow: hidden;
    }
    .paging-number-scroll{
        width: 100%;
        display: flex;
        align-items: center;
        transition: all 1s;
    }
    .page-number{
        flex-shrink: 0;
        width: 30px;
        padding: 2px 0;
        color: #373737;
        margin-right: 6px;
        cursor: pointer;
        text-align: center;
    }
    .page-number-bg{
        color: #ffffff;
        background: #9E9E9E;
    }
</style>